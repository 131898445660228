<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <div class="menu-box">
        <h2>入库申请</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="5">
              <el-input placeholder="请输入内容" v-model="getInfo.info">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="catalogue"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="10">
              <el-button type="success" @click="addStorageOpen()"
                >申请入库</el-button
              >
              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
            <el-col :span="9">
              <div class="border">
                <div
                  @click="changeItem(item)"
                  v-for="item in list"
                  :key="item.name"
                  class="border-item"
                  :class="[item.id == getInfo.differentiate ? 'active' : '']"
                >
                  {{ item.name }}
                </div>
              </div>
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            ref="multipleTable"
            :data="tableData"
            class="commodity-table"
            tooltip-effect="dark"
            @selection-change="handleCheckedSpecChange"
            :row-key="getRowKeys"
            :row-class-name="getRowClassName"
            style="width: 100%"
          >
            <el-table-column
              :reserve-selection="true"
              type="selection"
              :selectable="selectable"
              width="80"
            >
            </el-table-column>
            <el-table-column type="expand">
              <template slot-scope="scope">
                <!-- {{ scope.row.spec }} -->
                <el-tag v-if="scope.row.spec.length === 0"
                  >该商品暂无属性，请直接入库商品</el-tag
                >
                <el-checkbox-group v-else v-model="specCheckedList">
                  <el-checkbox
                    v-for="specItem in scope.row.spec"
                    :label="specItem.id"
                    :key="specItem.id"
                    >{{ specItem.key_name }}</el-checkbox
                  >
                </el-checkbox-group>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="商品名称"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="numbering"
              label="商品编号"
              width="150"
            ></el-table-column>
            <el-table-column prop="img" label="商品图" width="120">
              <template slot-scope="scope">
                <el-image :src="scope.row.img" style="width: 80px"></el-image>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="for_short"
              label="商品简称"
              width="100"
            ></el-table-column> -->
            <el-table-column
              prop="add_time"
              label="添加时间"
              width="110"
            ></el-table-column>

            <el-table-column label="商品状态" width="100">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.state === 1">
                  启用
                </el-tag>
                <el-tag v-else type="danger">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="class_name"
              label="分类名称"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="v_name"
              label="供应商名称"
              width="100"
            ></el-table-column>
          </el-table>
          <el-pagination
            @current-change="pageChange"
            :pager-count="15"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.getInfo.page_num"
            background
            :total="exp.num"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>

    <!-- 申请入库对话框 -->
    <el-dialog
      title="入库单"
      v-dialogDrag
      style="font-weight:700"
      :before-close="handleClose"
      :visible.sync="addDialogVisible"
      width="80%"
    >
      <el-form
        class="table-form"
        ref="addFormRef"
        :model="addForm"
        :rules="addRules"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="入库类型"
              prop="type"
              :label-width="formLabelWidth"
            >
              <el-select
                style="width:100%"
                v-model="addForm.type"
                placeholder="请选择入库类型"
              >
                <el-option
                  v-for="item in addType"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <!-- <el-input v-model="addForm.type" placeholder="请输入入库类型" /> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="入库时间"
              prop="add_time"
              :label-width="formLabelWidth"
            >
              <el-input :disabled="true" v-model="addForm.add_time" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="入库编号"
              prop="storage_no"
              :label-width="formLabelWidth"
            >
              <el-input :disabled="true" v-model="addForm.storage_no" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- <el-col :span="8">
            <el-form-item
              label="供应商"
              prop="supplier_id"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="addForm.supplier_id"
                style="width: 100%"
                placeholder="请选择供应商"
                clearable
              >
                <el-option
                  v-for="item in supplier"
                  :key="item.id"
                  :label="item.dealer_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item
              label="仓库"
              prop="warehouse_id"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="addForm.warehouse_id"
                style="width: 100%"
                placeholder="请选择仓库"
                clearable
              >
                <el-option
                  v-for="item in warehousesList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="采购员"
              prop="purchaser_id"
              :label-width="formLabelWidth"
            >
              <!-- <el-input :disabled="true" v-model="addForm.purchaser_name" /> -->
              <el-select
                v-model="addForm.purchaser_id"
                style="width: 100%"
                placeholder="请选择采购员"
                clearable
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              label="备注"
              prop="remark"
              :label-width="formLabelWidth"
            >
              <el-input v-model="addForm.remark" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <!-- 表格 -->
            <el-table
              ref="addTableRef"
              :data="addForm.add_data"
              class="add-table"
              max-width="1120"
              tooltip-effect="dark"
            >
              <el-table-column type="index"> </el-table-column>
              <el-table-column
                prop="name"
                label="商品名称"
                width="150"
              ></el-table-column>

              <el-table-column
                prop="numbering"
                label="商品编号"
                width="150"
              ></el-table-column>
              <el-table-column
                prop="specName"
                width="150"
                label="规格"
              ></el-table-column>
              <el-table-column width="120" label="*采购价(元)">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'add_data.' + scope.$index + '.unit_price'"
                    :rules="addRules.num"
                  >
                    <el-input
                      v-model="scope.row.unit_price"
                      size="mini"
                      min="1"
                      type="number"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column width="100" label="*入库数量">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'add_data.' + scope.$index + '.num'"
                    :rules="addRules.num"
                  >
                    <el-input
                      v-model="scope.row.num"
                      size="mini"
                      min="1"
                      type="number"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="100" label="*产地">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'add_data.' + scope.$index + '.product_place'"
                    :rules="addRules.num"
                  >
                    <el-input
                      v-model="scope.row.product_place"
                      size="mini"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="160" label="*生产日期">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'add_data.' + scope.$index + '.dom_time'"
                    :rules="addRules.num"
                  >
                    <!-- <el-input
                      size="mini"
                      v-model="scope.row.dom_time"
                    ></el-input> -->
                    <el-date-picker
                      size="mini"
                      :picker-options="pickerOptions"
                      v-model="scope.row.dom_time"
                      value-format="yyyy-MM-dd"
                      type="date"
                    >
                    </el-date-picker>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="150" label="*保质期">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'add_data.' + scope.$index + '.ed_time'"
                    :rules="addRules.num"
                  >
                    <el-input
                      min="1"
                      v-model="scope.row.ed_time"
                      size="mini"
                      type="number"
                    ></el-input>
                    <!-- <el-date-picker
                      size="mini"
                      v-model="scope.row.ed_time"
                      value-format="yyyy-MM-dd"
                      type="date"
                    > -->
                    <!-- </el-date-picker> -->
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="100" label="*单位">
                <template slot-scope="scope">
                  <el-form-item>
                    <el-select
                      v-model="scope.row.unit"
                      size="mini"
                      placeholder="单位"
                    >
                      <el-option
                        v-for="item in unit"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <!-- <el-table-column width="100" label="备注">
                <template slot-scope="scope">
                  <el-form-item>
                    <el-input v-model="scope.row.note" size="mini"></el-input>
                  </el-form-item>
                </template>
              </el-table-column> -->
            </el-table>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" @click="confirmAdd()">确 认</el-button>
      </span>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getState,
  getDel,
  getAdd,
  getOption,
  getOption2,
  addCommoditySpec,
  getCommoditySpec,
  editCommoditySpec,
} from '@/api/psi/commodity.js'
import { getModelList, getEditModelInfo } from '@/api/psi/commoditymodel.js'
import {
  getUserInfo,
  getWarehousesList,
  getGoodsList,
  getToday,
  getStorageNo,
  addGoodsToStorage,
  storageGoodsList,
  getUserlist,
  storageGoodsInfo,
  editStorageGoods,
} from '@/api/psi/storage.js'
import { allUnit } from '@/api/psi/unit.js'
import lodash from 'lodash'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      pickerOptions: {
        disabledDate(time) {
          // 禁用以后的时间
          return time.getTime() > Date.now() - 8.64e6
        },
      },
      addType: [
        { label: '采购入库' },
        { label: '生产入库' },
        { label: '调拨入库' },
        { label: '退货入库' },
        { label: '退料入库' },
        { label: '其他入库' },
      ],
      unit: [],
      //   unit: [
      //     {
      //       value: '份',
      //       label: '份',
      //     },
      //     {
      //       value: '箱',
      //       label: '箱',
      //     },
      //     {
      //       value: '袋',
      //       label: '袋',
      //     },
      //     {
      //       value: '瓶',
      //       label: '瓶',
      //     },
      //     {
      //       value: '罐',
      //       label: '罐',
      //     },
      //     {
      //       value: '个',
      //       label: '个',
      //     },
      //     {
      //       value: '杯',
      //       label: '杯',
      //     },
      //     {
      //       value: '克',
      //       label: '克',
      //     },
      //     {
      //       value: '千克',
      //       label: '千克',
      //     },
      //     {
      //       value: '包',
      //       label: '包',
      //     },
      //     {
      //       value: '顶',
      //       label: '顶',
      //     },
      //     {
      //       value: '台',
      //       label: '台',
      //     },
      //     {
      //       value: '套',
      //       label: '套',
      //     },
      //     {
      //       value: '小时',
      //       label: '小时',
      //     },
      //     {
      //       value: '间',
      //       label: '间',
      //     },
      //     {
      //       value: '辆',
      //       label: '辆',
      //     },
      //     {
      //       value: '双',
      //       label: '双',
      //     },
      //     {
      //       value: '把',
      //       label: '把',
      //     },
      //     {
      //       value: '根',
      //       label: '根',
      //     },
      //     {
      //       value: '米',
      //       label: '米',
      //     },
      //   ],
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      specSizeData: [],
      activeName: '1',
      cmdFormRules: {
        name: { required: true, message: '请输入商品名称', trigger: 'blur' },
        accessories: { required: true, message: '请选择是否有配件' },
        consumable: { required: true, message: '请选择是否易耗品' },
        // v_id: { required: true, message: '请选择供应商名称' },
        class_id: { required: true, message: '请选择商品分类' },
        differentiate: { required: true, message: '请选择产品类型' },
      },
      //供应商
      supplier: [],
      // 商品基础信息表单
      commodityForm: {
        name: '',

        for_short: '',
        spec_img: '',
        detail: '',
        info_img: [],
        accessories: 0,
        numbering: '123456',
        consumable: 0,
        v_id: '',
        class_id: [],
        item: [],
        task_id: '',
        differentiate: 2,
        slideshow: [],
        set_meal: [],
        meal: [{ name: '', amount: '' }],
      },
      optionsTask: [],
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      classifyOpt: [],
      title: '添加商品',
      commodityDialogVisible: false,
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'sub',
      },
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      optionsTask: [],
      delForm: {
        task_id: '',
      },
      dialogLog: {
        state: false,
      },
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'sub',
      },
      openState: true,
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 7,
        differentiate: 2,
      },
      //导航
      nav: [],
      //上级列表
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {},
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
      list: [
        { id: 1, name: '食品' },
        { id: 2, name: '用品' },
      ],
      tableLoading: false,
      addDialogVisible: false,
      addRules: {
        type: [
          { required: true, message: '请选择入库类型', trigger: 'blur' },
          {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur',
          },
        ],
        add_time: [
          { required: true, message: '请输入入库时间', trigger: 'blur' },
          {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur',
          },
        ],
        storage_no: [
          { required: true, message: '请输入入库编号', trigger: 'blur' },
          {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur',
          },
        ],
        // supplier_id: [
        //   { required: true, trigger: 'change', message: '请选择供应商' },
        // ],
        warehouse_id: [
          { required: true, trigger: 'change', message: '请选择仓库' },
        ],
        purchaser_id: [
          { required: true, trigger: 'change', message: '请选择采购员' },
        ],
        num: [{ required: true, message: '必填', trigger: 'blur' }],
      },

      addForm: {
        add_data: [],
      },
      //供应商
      supplier: [],
      warehousesList: [],
      // 选中的规格所有 id组成的数组
      specCheckedList: [],
      //选中的商品 对象数组
      checkedCommodityList: [],
      allCommodities: [],
      // 选中商品显示在添加表格里的数据
      addCommodityList: [],
      addSpecTableList: [],
      // 判断 有规格的商品 用户选没选规格
      specChosenFlag: true,
      userList: [],
    }
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
    computeMeal() {
      if (this.commodityForm.set_meal.length !== 0) {
        const temp = JSON.parse(JSON.stringify(this.commodityForm.set_meal))
        for (let i = 0; i < temp.length; i++) {
          const arr = temp[i].split(',')
          var res = []
          res.push({
            amount: arr[1],
            name: arr[0],
          })
        }
        return res
      }
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 处理商品勾选事件
    handleCheckedSpecChange(val) {
      console.log(val)
      this.checkedCommodityList = val

      // this.checkedCommodityList.forEach(commo=>{

      //     if(commo.spec.length===0){
      //         // 没有规格
      //         console.log('勾选了没有规格的商品');

      //     }else {
      //         // 有规格
      //         if(this.specCheckedList.length===0){
      //             // 没有勾选任何规格
      //             console.log('商品有规格却没有勾选1')
      //             this.specChosenFlag = false
      //             // this.$nextTick(()=>{
      //             //     this.$refs.multipleTable
      //             // })
      //             return
      //         }
      //         this.specCheckedList.forEach(specID=>{
      //             commo.spec.forEach(specItem=>{
      //                 if(specItem.id===specID){
      //                     console.log('勾选了商品规格')
      //                 }else {
      //                     console.log('商品有规格却没有勾选2')
      //                     this.specChosenFlag = false
      //                     return
      //                 }
      //             })
      //         })
      //     }

      // })
      // if(this.checkedCommodityList.length!==0){
      //     this.checkedCommodityList.forEach(item=>{
      //         if(item.spec.length!==0){
      //             // 该商品下面有规格
      //             item.spec.forEach(i=>{
      //                 if(this.specCheckedList.length===0){
      //                     console.log('选中商品却没有选中规格1');
      //                     this.specChosenFlag = false

      //                 }else {
      //                     this.specCheckedList.forEach(checkedSpecId=>{
      //                     if(i.id===checkedSpecId){
      //                         this.specChosenFlag = true

      //                     }else {
      //                     console.log('选中商品却没有选中规格2');

      //                         this.specChosenFlag = false

      //                     }
      //                 })
      //                 }

      //             })
      //         }else {
      //             console.log('该商品下没有规格')
      //             this.specChosenFlag = true
      //         }

      //     })
      // }else {
      //     this.specChosenFlag = true
      // }

      // if(!this.specChosenFlag){
      //     this.$message.warning('该商品下存在规格，请至少选择一项规格申请入库！')
      //     this.checkedCommodityList.pop()
      // }
    },

    changeItem(item) {
      this.getInfo.differentiate = item.id
      this.catalogue()
    },
    dialogOpen() {
      this.dialogLog.state = true
    },
    getRowKeys(row) {
      //console.log(row.id);
      // this.rowKeyArr.push(row.id)
      return row.id
    },
    selectable(row) {
      //console.log(row)
      return row.selected
    },
    // 获得所有选择的商品列表 及列表下的属性选择

    async getChosenCommodityList() {
      // const res = await getList()
      //console.log(res)
      this.allCommodities = this.tableData

      this.allCommodities.forEach((commo) => {
        if (commo.spec.length !== 0) {
          // 有规格
          commo.spec.forEach((specItem) => {
            // 遍历规格
            //this.specCheckedList
            this.specCheckedList.forEach((specId) => {
              if (specItem.id === specId) {
                //说明选择了这些规格 记下来这个商品
                // console.log(specItem.key_name)
                // console.log(commo.id);
                commo['mp_id'] = commo.id
                commo['goods_spec_id'] = specItem.id
                //commo['unit_price'] = commo.price

                const temp = lodash.cloneDeep(commo)
                temp.specName = specItem.key_name
                this.addCommodityList.push(temp)
              }
            })
          })
        } else {
          //没有规格
          this.checkedCommodityList.forEach((item) => {
            if (item.id === commo.id) {
              commo['mp_id'] = commo.id
              //commo['unit_price'] = commo.price
              this.addCommodityList.push(commo)
            }
          })
        }
      })
      //this.addCommodityList = Array.from(new Set(this.addCommodityList))
      // this.addSpecTableList = this.specCheckedList
      this.addForm.add_data = lodash.cloneDeep(this.addCommodityList)
    },

    // 获得供应商下拉菜单
    async getSupplier() {
      const { data: res } = await getOption2()
      const { data: res1 } = await getOption()
      const { data: res2 } = await getModelList()
      //console.log(res)
      this.supplier = res.data
      this.classifyOpt = res1.data
      this.modelList = res2.data
    },

    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //打开添加
    addOpen() {
      this.$router.push({
        path: 'addcommodity',
        query: {
          name: '增加商品',
        },
      })
    },

    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible = false
        this.dialogVisible = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },

    //目录接口
    async catalogue() {
      this.tableLoading = true
      let optionsTask = await this.$getOptionTask()
      this.optionsTask = optionsTask.data.data
      let info = await getList(this.getInfo)
      this.tableData = info.data.data
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
      this.tableLoading = false
    },

    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    // tab点击事件
    handleTabClick() {},
    addCommodityOpen() {
      this.commodityDialogVisible = true
      this.getSupplier()
    },

    // 处理关闭
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAdd()
          done()
        })
        .catch((_) => {})
    },
    cancelAdd() {
      this.addForm = {
        add_data: [],
      }
      this.addDialogVisible = false
      this.addCommodityList = []

      setTimeout(() => {
        this.$refs.addFormRef.clearValidate()
      }, 30)
    },
    // 取消 但不清空表单
    // 确认添加
    async confirmAdd() {
      delete this.addForm['purchaser_name']
      this.addForm.add_data.forEach((item) => {
        delete item['for_short']
        delete item['img']
        delete item['length']
        delete item['detail']
        delete item['weight']
        delete item['info_img']
        delete item['state']
        delete item['accessories']
        delete item['price']
        delete item['discount']
        delete item['clas']
        delete item['consumable']

        delete item['add_time']
        delete item['differentiate']
        delete item['slideshow']
        delete item['update_time']
        delete item['set_meal']
        delete item['selected']
        delete item['class_name']
        delete item['v_name']
        delete item['pd_time']
        if (item.spec && item.spec.length === 0) {
          item['goods_spec_id'] = ''
          delete item['spec']
        } else {
          delete item['spec']
        }
      })
      let form = lodash.cloneDeep(this.addForm)
      form.add_data = JSON.stringify(form.add_data)
      const res = await addGoodsToStorage(form)
      console.log(res)
      if (res.data.code === 1) {
        this.$message.success('申请入库成功！')
        this.cancelAdd()
        this.specCheckedList = []
        this.$refs.multipleTable.clearSelection()
        this.catalogue()
      } else {
        this.$message.error(res.data.message)
      }
    },
    //打开添加
    async addStorageOpen() {
      if (
        this.checkedCommodityList.length === 0 &&
        this.specCheckedList.length === 0
      ) {
        this.$message.warning('请先在列表中勾选需要申请入库的商品！')
      } else {
        // 已经勾选了商品
        const res = await getToday()
        // console.log(res)
        this.addForm.add_time = res.data.data
        const res2 = await getStorageNo()
        // console.log(res2);
        this.getSupplier()
        this.addForm.storage_no = res2.data.data
        // const res3 = await getUserInfo()
        // this.addForm.purchaser_id = res3.data.data.employee_id

        const res4 = await getWarehousesList()

        // console.log(res4)
        this.warehousesList = res4.data.data

        const res5 = await getUserlist()
        this.userList = res5.data.data
        this.addForm.purchaser_name = res5.data.data.name

        this.addDialogVisible = true
        this.getChosenCommodityList()
      }
    },
    // 获得供应商下拉菜单
    async getSupplier() {
      const { data: res } = await getOption2()

      //console.log(res)
      this.supplier = res.data
    },
    getRowClassName(row) {
      console.log(row)
      if (row.row.spec && row.row.spec.length === 0) {
        return 'hidden-expand'
      }
    },
    async getUnit() {
      const { data: res } = await allUnit()
      console.log(res.data)
      this.unit = res.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.getChosenCommodityList()
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
    this.getUnit()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}

.border {
  display: flex;
}

.border-item {
  border: 1px solid #eee;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 6px;
  color: #666;
  margin-right: 5px;
}

.active {
  color: #909399 !important;
  background: #f4f4f5 !important;
}

.btns {
  display: flex;
  justify-content: end;
  align-items: center;
}

.spec-checkbox {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
  padding-top: 20px;
  box-sizing: border-box;
}

.add-table >>> .el-form-item {
  margin-bottom: 0 !important;
}

.add-table >>> .el-form-item .el-input {
  width: 75px !important;
}
.add-table >>> .el-date-editor .el-input__inner {
  width: 123px !important;
}
.commodity-table >>> .el-checkbox {
  zoom: 200% !important;
}
.commodity-table >>> .hidden-expand .el-table__expand-column .cell {
  visibility: hidden;
}
</style>
